var $ = require('jquery');

$(document).scroll(function() {
    if ($('.towers-container').length > 0) {
        checkOffset();
    }
});

function checkOffset() {

    if($('.towers-container').offset().top + $('.tower-left').height() >= $('.site-footer').offset().top - 35) {
        $('.towers-container').css('top', $('.site-footer').offset().top - $('.tower-left').height() - 35);
        $('.towers-container').css('left', $('.towers-container').offset().left);
        $('.towers-container').css('width', $('.towers-container').width());
        $('.towers-container').css('position', 'absolute');
    }

    if($(document).scrollTop() + window.innerHeight < $('.site-footer').offset().top) { // restore when you scroll up
        $('.towers-container').css('top', "");
        $('.towers-container').css('left', "");
        $('.towers-container').css('width', "");
        $('.towers-container').css('position', 'sticky');
    }
}